window.addEventListener("load", function () {

    //style relevant buttons to fit the design.

    const arrow = '<i class="fa-light fa-circle-arrow-right"></i>';

    const text = document.querySelectorAll('.wp-content');

    text.forEach(text => {
        const textLink = text.querySelectorAll('a');

        if (text.contains(text.querySelector('a'))){
            text.classList.add('button-p');
        }

        textLink.forEach(link => {
            link.classList.add('default-button');
        });
    });

    const defaultButton = document.querySelectorAll('.default-button');

    defaultButton.forEach(defaultButton => {
        const linkText = defaultButton.innerHTML;
        const defaultButtonStyle = defaultButton as HTMLElement;
        defaultButtonStyle.style.display = 'flex';

        defaultButton.innerHTML = '<div class="default-button-text">' + linkText + '</div>' + arrow;
    })
})