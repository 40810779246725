window.addEventListener("load", function () {
    const header = document.querySelector('.dealer-header-wrapper');
    const lastPost = document.querySelector('.last-project-post');
    const dealersBg = document.querySelector('.dealers-background');

    //make the background color of the Dealers component on the frontpage fit to the last project in the projects list.

    if (document.body.classList.contains('home')) {
        if (lastPost?.classList.contains('bg-zz-blue-dark')) {
            header?.classList.add('bg-zz-blue-dark');
            dealersBg.classList.add('bg-zz-blue-dark');
        } else {
            header?.classList.add('bg-zz-blue');
            dealersBg.classList.add('bg-zz-blue');
        }
    } else {
        dealersBg?.classList.add('bg-zz-blue-dark');
    }

    //run projectsBackground on window load

    projectsBackground();
})

window.addEventListener('resize', () => {

    //run projectsBackground on window resize

    projectsBackground();
})

//Add absolute background rows to make the even rows a different blue color.

function projectsBackground() {
    const background = document.querySelector('.ProjectenComponent-background');

    if (document.contains(background)) {

        const projectWrapper = document.querySelector('.ProjectenComponent');
        const projects = projectWrapper?.querySelectorAll<HTMLElement>('.project-container');

        background.innerHTML = '';

        const projectArray = [];

        projects.forEach(project => {
            projectArray.push(project);
        })

        let nth = 3;

        if ((window.innerWidth <= 991) && (window.innerWidth >= 768)) {
            nth = 2;
        } else if (window.innerWidth <= 767) {
            nth = 1;
        }

        const firstProject = projectArray.shift();

        const every_nth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);
        const newProjectsArray = every_nth(projectArray, nth);

        newProjectsArray.unshift(firstProject);

        newProjectsArray.forEach(newProject => {
            const newRow = document.createElement('div');

            newRow.style.height = (newProject.offsetHeight + 48) + 'px';
            newRow.style.width = '100vw';

            background.appendChild(newRow);
        })
    }
}