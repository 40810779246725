window.addEventListener("load", function () {

    //Code to toggle the phone menu on click

    const header = document.querySelector('.top-header') as HTMLElement;
    const togglePhoneMenu = header.querySelector('.phone-menu-button') as HTMLElement;
    const phoneMenu = document.querySelector('.phone-menu') as HTMLElement;

    togglePhoneMenu.addEventListener('click', () => {

        if (phoneMenu.classList.contains('open')) {
            phoneMenu.classList.remove('open');
            document.body.classList.remove('no-scroll');
        } else {
            phoneMenu.classList.add('open');
            document.body.classList.add('no-scroll');
        }
    })

    window.addEventListener('resize', () => {

        if (phoneMenu.classList.contains('open')) {
            console.log(togglePhoneMenu.offsetHeight);

            if (togglePhoneMenu.offsetHeight == 0) {
                phoneMenu.classList.remove('open');
            }
        }
    })
})