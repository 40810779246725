export class Modal {
    public static open(id: string): void {
        const modal = document.querySelector(`[data-modal="${id}"]`);
        if (modal) {
            modal.classList.add('modal--open');
        }
    }
    
    public static close(id: string): void {
        const modal = document.querySelector(`[data-modal="${id}"]`);
        if (modal) {
            modal.classList.remove('modal--open');
        }
    }
    
    public static toggle(id: string): void {
        const modal = document.querySelector(`[data-modal="${id}"]`);
        if (modal) {
            modal.classList.toggle('modal--open');
        }
    }

    public static closeAll() {
        const elements= document.querySelectorAll(`[data-modal]`);
        elements.forEach(i => i.classList.remove('modal--open'));
    }
}
