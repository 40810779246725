window.addEventListener("load", function () {

    //code to filter the colors on the color archive page.

    const colorCategoryButton = document.querySelectorAll('.color-category');
    const colorCategory = document.querySelectorAll('.color-term-archive');
    const colorComponent = document.querySelector('.KleurenComponent');

    if (document.body.classList.contains('home')) {
        bgHeight(colorComponent);
    } else {
        colorCategory.forEach(category => {
            bgHeight(category);
        })
    }

    colorCategoryButton.forEach(button => {

        button.addEventListener('click', () => {

            colorCategoryButton.forEach(allButton => {
                allButton.classList.remove('show');
            })

            button.classList.add('show');

            if (button.id == 'all') {
                colorCategory.forEach(category => {

                    category.classList.add('show');
                    category.classList.remove('first');

                    if (category == colorCategory[0]) {
                        category.classList.add('first');
                    }
                })
            } else {
                colorCategory.forEach(category => {

                    category.classList.remove('show');

                    if (button.id == category.id) {
                        button.classList.add('show');
                        category.classList.add('show');
                        category.classList.add('first');
                    }
                })
            }

            colorCategory.forEach(category => {
                bgHeight(category);
            })
        })
    })

    //function to add a background to the first row of colors, so it fits the design.

    function bgHeight(archive) {
        if ((archive.classList.contains('first') && archive.classList.contains('show')) || archive.classList.contains('KleurenComponent')) {

            const bg = archive.querySelector('.background') as HTMLElement;
            const color = archive.querySelector('.color') as HTMLElement;

            bg.style.height = String(0.5 * color.offsetHeight) + 'px';

            window.addEventListener('resize',
                () => {
                    bg.style.height = String(0.5 * color.offsetHeight) + 'px';
                })
        }
    }
})