import * as url from "url";

window.addEventListener("load", function () {

    //Code to open/close the tabs on the specifications page

    const toggles = document.querySelectorAll('.spec_toggle');
    const tabs = document.querySelectorAll('.spec_tab');

    toggles.forEach(toggle => {

        toggle.addEventListener('click', () => {
            const name = toggle.id;

            toggles.forEach(toggle => {
                toggle.classList.remove('open');
            })

            toggle.classList.add('open');

            tabs.forEach(tab => {
                if (tab.classList.contains(name)) {
                    if (!tab.classList.contains('open')) {
                        tab.classList.add('open');
                    }
                } else {
                    tab.classList.remove('open');
                }
            })
        })
    });

    const downloadButton = document.getElementById('download-details-button');
    const downloadToggle = document.getElementById('downloads');
    const downloadTab = document.getElementById('spec_tab_downloads');

    function downloadDetails() {
        if (!downloadToggle.classList.contains('open')) {
            toggles.forEach(toggle => {
                toggle.classList.remove('open');
            })
            downloadToggle.classList.add('open');

            tabs.forEach(tab => {
                tab.classList.remove('open');
            })

            downloadTab.classList.add('open');
        }
    }

    if (document.body.contains(downloadButton)) {
        downloadButton.addEventListener('click', () => {
            downloadDetails();
        })
    }

    const detail_URL = '/specificaties/#download-details';

    if (String(window.location.pathname + window.location.hash) == detail_URL) {
        downloadDetails();
    }

    //subtitle code for table with info

    const subtitles = document.querySelectorAll('.subtitle');

    subtitles.forEach(subtitle => {
        const subtitleGrid = subtitle.querySelector('ul');

        if (subtitle.contains(subtitleGrid)) {
            subtitle.classList.add('subtitle-grid');
        }
    })

    //Code to open/close the tabs on the specifications page

    const variantButtons = document.querySelectorAll('.variant');
    const variants = document.querySelectorAll('.variant-specifications');

    variants.forEach(variant => {
        if (variant.id === '1') {
            variant.classList.add('open');
        }
    })

    variantButtons[0]?.classList.add('show');

    variantButtons.forEach(button => {

        button.addEventListener('click', () => {
            const name = button.id;

            variantButtons.forEach(button => {
                button.classList.remove('show');
            })

            button.classList.add('show');

            variants.forEach(variant => {
                if (variant.id === name) {
                    if (!variant.classList.contains('open')) {
                        variant.classList.add('open');
                    }
                } else {
                    variant.classList.remove('open');
                }
            })
        })
    });
})