window.addEventListener("load", function () {

    //Translate the specifications table content from Wordpress to either checks or X marks.

    const tableTab = document.querySelector('.coating');
    const tableRow = tableTab?.querySelectorAll('tr');

    const check = '<i class="fa-solid fa-check"></i>';
    const cross = '<i class="fa-solid fa-xmark"></i>';

    tableRow?.forEach(tr => {
        const tableData = tr.querySelectorAll('td');
        const productName = tr.querySelectorAll('td')[0];

        tableData.forEach(td => {
            if (td.innerHTML.toLowerCase() == 'ja') {
                td.innerHTML = check;
            } else{
                if (td !== productName){
                    td.innerHTML = cross;
                }
            }
        })
    })
})