import {MarkerClusterer} from "@googlemaps/markerclusterer";

const dealers = (window as any).dealers ?? [];

//function to change icon color

function dynamicMarkers(map, marker) {
    map.addListener('maptypeid_changed', function () {
        marker.setIcon({
            url: (window as any).theme_directory + '/images/Oval.svg',
            scaledSize: new google.maps.Size(25, 25),
        });
        marker.setLabel({text: "1", color: 'white', fontSize: "16px", fontWeight: "500"})
    })
}

//function to make the markers

function makeMarkers(map, infoWindow) {
    return dealers.map((dealer, i) => {

        //individual marker info

        const marker = new google.maps.Marker({
            position: {
                lat: dealer.lat,
                lng: dealer.lng
            },
            label: {text: "1", color: 'white', fontSize: "16px", fontWeight: "500"},
            icon: {
                url: (window as any).theme_directory + '/images/Oval.svg',
                scaledSize: new google.maps.Size(25, 25),
            },
        });

        //pan to marker and make the infoWindow on clicking the marker

        marker.addListener("click", () => {
            map.panTo(marker.getPosition());
            fillInfoWindow();
            infoWindow.open(map, marker);
        });

        function fillInfoWindow() {
            infoWindow.setContent(
                "<section><ul data-dealer-info='" + JSON.stringify(dealer) + "' class='map-dealer-info card-content' id='" + dealer.id + "'>" +
                '<li><h3>' + dealer.name + '</h3></li>' +
                '<li>' + dealer.street + '</li>' +
                '<li>' + dealer.postalcode + '</li>' +
                '<li>' + dealer.city + '</li></ul>' +
                '<div class="dealer-button mt-2" id="map-dealer-button"><div class="text font-medium" id="map-dealer-button-content">CONTACT OPNEMEN</div>' +
                '<i class="fa-light fa-circle-arrow-right"></i></div></section>',
            );
        }

        //pan to marker and make the infoWindow on clicking a dealer block in search list

        const dealerBlocks = document.querySelectorAll('.dealer-info-list');

        dealerBlocks.forEach(dealerBlock => {

            dealerBlock.addEventListener('click', function () {
                const latLng = new google.maps.LatLng(dealer.lat, dealer.lng);
                if (dealerBlock.id == dealer.id) {
                    fillInfoWindow();
                    infoWindow.close();
                    map.panTo(latLng);
                    infoWindow.open(map, marker);
                    map.setZoom(16);
                }
            })
        })

        return marker;
    });
}

//main Maps function

function initMap(): void {

    if (document.contains(document.querySelector('#map'))) {

        //declare the locations

        let locations = [];

        dealers.forEach(function (dealer) {
            const {lat, lng} = dealer;
            locations.push({lat: lat, lng: lng});
        });

        //make the map

        const map = new google.maps.Map(
            document.getElementById("map") as HTMLElement,
            {
                zoom: 7.5,
                center: {lat: 52.2, lng: 5.2},
                mapId: 'd4e2342286b51384',
            }
        );

        //make the infoWindow

        const infoWindow = new google.maps.InfoWindow({
            content: "",
            disableAutoPan: true,
        });

        //load function for markers

        const markers = makeMarkers(map, infoWindow);

        markers.forEach(marker => {
            dynamicMarkers(map, marker)
        })

        //make the marker cluster marker

        const renderer = {
            render: function ({count, position}) {
                return new google.maps.Marker({
                    label: {text: String(count), color: "white", fontSize: "16px", fontWeight: "500"},
                    position,
                    icon: {
                        url: (window as any).theme_directory + '/images/Oval.svg',
                        scaledSize: new google.maps.Size(((2 * count) + 25), ((2 * count) + 25)),
                    },
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                })
            }
        }

        new MarkerClusterer({markers, map, renderer});
    }
}

declare global {
    interface Window {
        initMap: () => void;
    }
}
window.initMap = initMap;
