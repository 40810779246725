import {Modal} from "../helpers/Modal";

window.addEventListener('load', () => {

    //code to close modal

    const closeButtons = Array.from(document.querySelectorAll('[data-close-modal]'));
    closeButtons.forEach(item => {
        const value = item.getAttribute('data-close-modal');
        item.addEventListener('click', () => {
            if(value) {
                Modal.close(value);
            } else {
                Modal.closeAll();
            }
        })
    });
})