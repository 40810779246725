const dealersComponent = document.querySelector('.DealersComponent');
const dealerSearchWrapper = dealersComponent?.querySelector('.dealer-searchbar-wrapper') as HTMLElement;

//height and padding of dealer list container and padding of Map

function mapContainer() {
    if (document.contains(dealersComponent)) {
        const mapWrapper = dealersComponent.querySelector('.map-wrapper') as HTMLElement;
        const dealerList = dealersComponent.querySelector('.dealer-list') as HTMLElement;

        if (mapWrapper.offsetWidth <= (Math.ceil(0.5 * window.innerWidth))) {
            const dealerForm = dealersComponent?.querySelector('.search-map-wrapper') as HTMLElement;
            const dealerInfoLists = dealersComponent?.querySelectorAll('.dealer-info-list');
            const header = document.querySelector('.top-header') as HTMLElement;
            const padding = (window.innerWidth - header.offsetWidth) * 0.5;

            dealerForm.style.paddingRight = (padding + 16) + 'px';
            dealerSearchWrapper.style.paddingLeft = (padding + 16) + 'px';

            dealerInfoLists.forEach(list => {
                const infoList = list as HTMLElement;
                infoList.style.paddingLeft = (padding + 16) + 'px';
            })

            dealerList.style.height = String(mapWrapper.offsetHeight - dealerSearchWrapper.offsetHeight) + 'px';

        } else {
            dealerList.style.height = '80vh';
        }
    }
}

//on window load, run mapContainer and add function to the searchbar.

window.addEventListener('load', () => {
    mapContainer();

    if (document.contains(document.querySelector('.DealersComponent'))) {
        const searchbar = document.querySelector('#dealer-searchbar') as HTMLInputElement;
        const select = document.querySelector('#partners') as HTMLInputElement;
        const resultDealers = document.querySelectorAll('.dealer-info');

        select?.addEventListener('input', () => {
            const dealers = (window as any).dealers ?? [];
            const searchInput = select.value;
            let shownDealers = [];

            function filterByValue(array, string) {
                return array.filter(o =>
                    Object.keys(o).some(k => o[k].toString().toLowerCase().replace(' ', '').includes(string.toLowerCase().replace(' ', ''))));
            }

            resultDealers.forEach(dealerElement => {
                dealerElement.classList.remove('even');

                if (searchInput.length > 0) {
                    dealerElement.classList.add('hideDealer');
                } else {
                    dealerElement.classList.remove('hideDealer');
                }
            })

            const filteredDealers = filterByValue(dealers, searchInput);


            filteredDealers.forEach(dealer => {
                const dealerElement = document.querySelector('#dealer-' + dealer.id);
                if (dealerElement) {
                    dealerElement.classList.remove('hideDealer');

                    shownDealers.push(dealerElement);
                }
            })

            shownDealers.forEach(shownDealer => {
                if (shownDealers.indexOf(shownDealer) % 2 !== 0) {
                    shownDealer.classList.add('even');
                }
            })
        })
    }
})

//on window resize, run mapContainer to keep padding and height of relevant elements up to date.

window.addEventListener('resize', (event) => {
    if (document.contains(document.querySelector('.DealersComponent'))) {
        mapContainer();
    }
})
