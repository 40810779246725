import {Modal} from "../helpers/Modal";

window.addEventListener("load", function () {
    const afterPopupOpener = document.querySelectorAll('.card-content');

    //fill and open the Modal on clicking the element that has
    //the 'open-popup' class

    afterPopupOpener.forEach(cardContent => {
        const togglePopup = cardContent.previousElementSibling;

        togglePopup?.addEventListener('click', function () {
            const popup = document.querySelector('.popup');
            const card = popup.querySelector('.card');

            //create the Modal card content

            const oldCardContent = popup.querySelector('.card-content');
            if (oldCardContent){
                oldCardContent.remove();
            }

            const newCard = document.createElement('div');
            const newCardContent = cardContent;
            newCard.className = 'card-content';
            newCard.innerHTML = cardContent.innerHTML;

            //insert content in the card

            card.appendChild(newCardContent);

            //Open the modal

            Modal.toggle('default-modal');
        })
    })
})