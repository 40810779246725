import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

window.addEventListener('load', () => {

    function createSplide(selector, options, extensions={}) {
        if (!document.querySelector(selector)){
            return;
        }

        const carousel = new Splide(selector, options);

        carousel.mount( extensions );
    }

    createSplide('#splide-home', {
        perPage: 1,
        arrows: false,
        type: 'loop',
        autoplay: true,
    })

    createSplide('#splide-partners', {
        autoScroll: {
            speed: 1,
        },
        pauseOnHover: true,
        rewind: true,
        perPage: 10,
        arrows: false,
        type: 'loop',
        gap: '3rem',
    }, { AutoScroll })
})

